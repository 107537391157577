import * as React from 'react';
import { Headline, Paragraph, Title } from '@bojagi/pablo/Typography';
import { Link } from 'gatsby';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ClosingBox } from '../sections/ClosingBox';
import { Emoji } from '../components/Emoji';
import { SimpleText } from '../sections/SimpleText';

const IndexPage = () => (
  <Layout showLogo>
    <Seo title="The CEO Page" route="for-ceos" socialPreviewImage="bojagi-social-preview-ceo.jpg" />
    <SimpleText>
      <Headline>
        <Emoji emoji="👨‍💼" label="CEO" /> The Bojagi CEO page
      </Headline>
      <Paragraph>
        Someone from your design or tech team wants to get your approval for using Bojagi in their
        workflow. It shows your team <strong>cares</strong> about the quality of their work and want
        to incrementally improve their processes.
      </Paragraph>
      <Paragraph>
        Bojagi increases the efficiency of designer-to-developer collaboration. Designers are
        empowered to get critical feedback to developers sooner.
      </Paragraph>

      <Title>Veteran team</Title>
      <Paragraph>
        The <Link to="/team">founding team</Link> has 25 years of combined working experience and
        have built services facilitating hundreds of thousands of users.
      </Paragraph>

      <Title>Supporting the industry standard</Title>
      <Paragraph>
        Bojagi extends Storybook, which is{' '}
        <a href="https://storybook.js.org/">industry standard tooling</a>. Teams that integrate with
        Bojagi enjoy collaborative tools and enhanced existing processes.
      </Paragraph>
      <Paragraph>
        If you decide to no longer use Bojagi, your engineers can still use the tooling on their own
        machines freely.
      </Paragraph>
      <Paragraph>This means that no code is thrown away.</Paragraph>

      <Title>Competitively priced and risk-free</Title>
      <Paragraph>There are no contracts, fees, or licence charges for Bojagi.</Paragraph>
      <Paragraph>
        Our pricing is transparent. We <Link to="/pricing">charge per project</Link>, not by usage.
      </Paragraph>

      <Title>Talk to us / Request a demo</Title>
      <Paragraph>
        Schedule a call or to{' '}
        <a href="https://calendly.com/simon-males/designer-user-test?back=1&month=2021-01">
          see Bojagi in action
        </a>
        .{' '}
      </Paragraph>
      <Paragraph>
        See what we have to say to <Link to="/for-designers">Designers</Link> and{' '}
        <Link to="/for-developers">Developers</Link> about Bojagi, too!
      </Paragraph>
    </SimpleText>
    <Container>
      <ClosingBox title="Convinced?">
        Try Bojagi today and see how it works in action. We are free during the open Beta!
      </ClosingBox>
    </Container>
  </Layout>
);

export default IndexPage;
